<template lang="pug">
v-card
  .supplies-table__row
    .supplies-table__row-head
      .supplies-table__row-head-cell {{ supply.ingredient_name }}
      .supplies-table__row-head-cell {{ supply.weight }} {{ 'units.kg' | translate }}
      .supplies-table__row-head-cell {{ supply.remainder }} {{ 'units.kg' | translate }}
      .supplies-table__row-head-cell {{ supply.cost }}
      .supplies-table__row-head-cell {{ supply.provider_name }}
      .supplies-table__row-head-cell {{ supply.created_at | date }}
          v-btn(
            icon
            small
            @click="showHistory")
            v-chevron(:value="toggled")
      .supplies-table__row-head-cell
          v-btn(
            icon
            :disabled="!canShowEdit"
            @click="edit(supply)"
            v-if="canShowEdit"
          ).table__button.table__button--settings-left
            icon-gear.table__settings

    .supplies-table__row-body(v-if="toggled")
      table.table.table--small.table--no-background.table--cell-overflow-hidden
        thead.table__header
          tr.table__row.table__row--header
            th.table__cell {{ 'base.name' | translate }}
            th.table__cell {{ 'pages.storage.amount_supply' | translate }}, {{ 'units.kg' | translate }}
            th.table__cell {{ 'pages.storage.supply_remainder' | translate }}, {{ 'units.kg' | translate }}
            th.table__cell {{ 'base.price' | translate }}
            th.table__cell {{ 'base.provider' | translate }}
            th.table__cell {{ 'base.date' | translate }}
            th.table__cell
        tbody.table__body
          tr.table__row.table__row--body.table__row--no-hover(v-if="supply.histories && supply.histories.length === 0")
            td.table__cell.text-center(colspan="5") {{ 'base.no_data' | translate}}
          tr.table__row.table__row--body.table__row--no-hover(v-for="(item, index) in supply.histories" :key="index")
            td.table__cell {{ item.ingredient_name }}
            td.table__cell {{ item.weight }} {{ 'units.kg' | translate }}
            td.table__cell {{ item.remainder }} {{ 'units.kg' | translate }}
            td.table__cell {{ item.cost }}
            td.table__cell {{ item.provider_name }}
            td.table__cell {{ item.created_at | date }}
            td.table__cell
              v-btn(
                icon
                :disabled="!canShowEdit"
                @click="edit(item)"
                v-if="canShowEdit"
              ).table__button.table__button--settings-left
                icon-gear.table__settings

        tfoot.table__footer(
          v-if="!loading && showPagination")
          tr.table__row.table__row--footer
            td.table__cell(colspan="5")
              .table__pagination
                v-paginate(
                  v-model="page"
                  :page-count="pageCount"
                  :click-handler="handler")

  v-dialog(
    max-width="348"
    v-model="modals.showEdit")
    edit-modal(
      :item="editItem"
      @close="closeEdit")

</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import paginationMixin from '@/mixins/pagination.mixin'
  import IconGear from '@/components/icons/IconGear'
  import EditModal from './modals/EditModal.vue'

  const PAGE_SIZE = 10

  export default {
    name: 'SupplyRow',

    mixins: [paginationMixin],

    components: {
      IconGear,
      EditModal
    },

    data: () => ({
      editItem: null,
      toggled: false,
      modals: {
        showEdit: false
      }
    }),

    props: {
      supply: {
        type: Object,
        required: true
      }
    },

    computed: {
      ...mapGetters([
        'suppliesHistory',
        'suppliesFilters',
        'user'
      ]),
      canShowEdit () {
        return true
      },
      filters () {
        return this.suppliesFilters
      }
    },

    methods: {
      ...mapActions([
        'fetchSuppliesHistory'
      ]),
      edit (item) {
        this.editItem = item
        this.modals.showEdit = true
      },
      closeEdit () {
        this.editItem = null
        this.modals.showEdit = false
        this.toggled = !this.toggled
        this.showHistory()
      },
      async showHistory () {
        this.toggled = !this.toggled
        if (this.toggled) {
            const ingredientID = this.supply.ingredient_id
            this.setupPagination(() => this.fetchSuppliesHistory({ ingredientID }))
            this.page = 1
        }
      },

      async handler (page) {
        this.loading = true
        const params = {
          page: this.page,
          ...(this.filters || {})
        }
        const ingredientID = this.supply.ingredient_id
        const { data, total } = await this.$store.dispatch('fetchSuppliesHistory', { ingredientID, params })
        this.items = data
        this.pageCount = Math.floor(total / PAGE_SIZE) + (total % PAGE_SIZE > 0 ? 1 : 0)
        this.loading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .supplies-table__row {

    &-head {
      position: relative;
      display: flex;
      gap: 8px;
      align-items: center;
      gap: 8px;
      padding: .8rem 1.5rem;

      &-cell {
        width: 20%;

        &:nth-child(1) {
          width: 30%;
          margin-right: 2%;
          text-decoration: underline;
        }
        &:last-child {
          width: 5%
        }
      }
    }

    &-body {
      border-top: .6rem solid $color-gray-light-2;
    }

    .table__row--body:last-child {
      border-bottom: none;
    }

    .planning-table__icon {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
    }
  }

  .text-center {
    text-align: center;
  }

  .table__row--header .table__cell:last-child,
  .table__row.table__row--body.table__row--no-hover .table__cell:last-child {
      width: 5%;
  }
</style>
